(function () {

    window.julepStart = julepStart;
    window.opinaryStart = opinaryStart;
    window.zazuStart = zazuStart;
    window.facebookStart = facebookStart;
    window.youtubeStart = youtubeStart;
    window.spotifyStart = spotifyStart;
    window.twitterStart = twitterStart;
    window.instagramStart = instagramStart;
    window.zdfStart = zdfStart;
    window.ardStart = ardStart;
    window.mailchimpStart = mailchimpStart;
    window.googleMapsStart = googleMapsStart;
    window.degrees23Start = degrees23Start;
    window.kdoStart = kdoStart;
    window.storifymeStart = storifymeStart;
    window.arcgisStart = arcgisStart;

    function julepStart() {

        let elements = document.getElementsByClassName('julePodcast');

        if (elements) {
            for (let i = 0; i < elements.length; i++) {
                let script = document.createElement("script");
                script.src = "https://cdn.julephosting.de/player/main.js";
                script.setAttribute('data-episode', elements[i].getAttribute('data-episode'));
                script.setAttribute('data-config', elements[i].getAttribute('data-config'));
                script.layout = "false";
                elements[i].innerHTML = '';
                elements[i].appendChild(script);
            }
        }
    }

    function opinaryStart() {
        let elements = document.getElementsByClassName('opinaryPlaceholder');

        if (elements.length > 0 ) {
            for (var i = 0; i < elements.length; i++) {
                let div = elements[i].getAttribute('data-div');
                elements[i].innerHTML = div;
            }
            var scriptEmbed = document.createElement("script");
            scriptEmbed.async="async";
            scriptEmbed.src="//widgets.opinary.com/embed.js";
            document.body.appendChild(scriptEmbed);
        } else {
            var script = document.createElement("script");
            script.async = "async";
            script.src = "https://widgets.opinary.com/a/ommedien.js";
            document.body.appendChild(script);
        }
    }
    function twitterStart() {
        let elements = document.getElementsByClassName('xPlaceholder');

        for (var i = 0; i < elements.length; i++) {
            let quote = elements[i].getAttribute('data-blockquote');
            elements[i].innerHTML = quote;
        }

        var script = document.createElement("script");
        script.async="async";
        script.src="https://platform.twitter.com/widgets.js";
        document.body.appendChild(script);
    }

    function zazuStart() {
        var script = document.createElement("script");
        script.defer = true; script.src = "https://widget.zazuapp.co/widget.js";
        document.body.appendChild(script);
        var elements = document.getElementsByClassName('zazu');
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none';
        }
    }

    function storifymeStart() {
        loadIFrameCode('storifymeIFramePlaceholder');
        var script = document.createElement("script");
        script.defer = true; script.src = "https://cdn.storifyme.xyz/static/web-components/storifyme-elements.min.js";
        document.body.appendChild(script);
        var elements = document.getElementsByClassName('storifymePlaceholder');
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none';
        }
    }

    function facebookStart() {
        loadIFrameCode('fbPlaceholder');
    }

    function youtubeStart() {
        loadIFrameCode('ytPlaceholder');
    }

    function spotifyStart() {
        loadIFrameCode('spotifyPlaceholder');
    }
    function mailchimpStart() {

        let elements = document.getElementsByClassName('mailchimpPlaceholder');
        for (var i = 0; i < elements.length; i++) {
            let embedCode = elements[i].getAttribute('data-embedCode');
            elements[i].innerHTML = embedCode;
        }

        var script = document.createElement("script");
        script.src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
        script.onload = function() {
            mailChimpValidation();
        };
        document.body.appendChild(script);
    }
    function instagramStart() {
        let elements = document.getElementsByClassName('instagramPlaceholder');

        for (var i = 0; i < elements.length; i++) {
            let quote = elements[i].getAttribute('data-blockquote');
            elements[i].innerHTML = quote;
        }

        var script = document.createElement("script");
        script.async="async";
        script.src="//www.instagram.com/embed.js";
        document.body.appendChild(script);
    }

    function zdfStart() {
        loadIFrameCode('zdfPlaceholder');
    }

    function ardStart() {
        loadIFrameCode('ardPlaceholder');
    }

    function googleMapsStart() {
        loadIFrameCode('gmapsPlaceholder');
    }
    function degrees23Start() {
        loadIFrameCode('degreesPlaceholder');
    }
    function kdoStart() {
        loadIFrameCode('kdoPlaceholder');
    }
    function arcgisStart() {
        loadIFrameCode('arcgisPlaceholder');
    }

    function loadIFrameCode(placeholder) {
        let elements = document.getElementsByClassName(placeholder);

        for (var i = 0; i < elements.length; i++) {
            let embedCode = elements[i].getAttribute('data-embedCode');
            elements[i].innerHTML = embedCode;
        }
    }

    function mailChimpValidation () {
        function checkSelectedNewsletter(event) {
            var form = document.getElementById("mc-embedded-subscribe-form");
            var checkboxes = form.querySelectorAll('input[name^="group[14989]"]');
            if(checkboxes.length) {
                var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
                if (!checkedOne) {
                    event.preventDefault();
                    document.getElementById("mce_fieldset_marketing_error").removeAttribute("style");
                    return false;
                } else {
                    document.getElementById("mce_fieldset_marketing_error").setAttribute("style", "display:none;");
                    return true;
                }
            }
        }
        var submitButton = document.getElementById("mc-embedded-subscribe");
        if(submitButton) {
            submitButton.addEventListener("click", checkSelectedNewsletter);
        }

        (function($) {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0] = "EMAIL";
            ftypes[0] = "email";
            fnames[1] = "FNAME";
            ftypes[1] = "text";
            fnames[2] = "LNAME";
            ftypes[2] = "text";
            fnames[3] = "ADDRESS";
            ftypes[3] = "address";
            fnames[4] = "PHONE";
            ftypes[4] = "phone";
            fnames[5] = "MMERGE5";
            ftypes[5] = "text";
            fnames[6] = "MMERGE6";
            ftypes[6] = "text";
            fnames[8] = "MMERGE8";
            ftypes[8] = "text";
            /*
             * Translated default messages for the $ validation plugin.
             * Locale: DE
             */
            $.extend($.validator.messages, {
                required: "Dieses Feld ist ein Pflichtfeld.",
                maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
                minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
                rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
                email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
                url: "Geben Sie bitte eine gültige URL ein.",
                date: "Bitte geben Sie ein gültiges Datum ein.",
                number: "Geben Sie bitte eine Nummer ein.",
                digits: "Geben Sie bitte nur Ziffern ein.",
                equalTo: "Bitte denselben Wert wiederholen.",
                range: $.validator.format("Geben Sie bitten einen Wert zwischen {0} und {1}."),
                max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
                min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
                creditcard: "Geben Sie bitte ein gültige Kreditkarten-Nummer ein."
            });
        }(jQuery));
        var $mcj = jQuery.noConflict(true);
    }
}());